<template>
    <div class="w1200">
        <div class="title text-2xl font-bold text-[#1D2129]">联系我们</div>
        <div class="locationInfo">
            <div class="borderLine"></div>

            <p class="ads">地址：北京市海淀区万泉庄路15号（100089）创而新大厦4层</p>
            <p class="email">邮箱：keli@k12smartedu.com</p>

            <div class="mapBox">
                <div id="map-container"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    data() {
        return {
            map: null
        }
    },
    mounted() {
        AMapLoader.load({
            key: "ee3859efd02bb391b396b756e4046d6c", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
            .then((AMap) => {
                let maker = new AMap.Marker({
                    icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                    position: [116.30028684351728, 39.96869829817237],
                    offset: new AMap.Pixel(-13, -30)
                });

                this.map = new AMap.Map("map-container", {
                    // 设置地图容器id
                    viewMode: "3D", // 是否为3D地图模式
                    zoom: 17, // 初始化地图级别
                    center: [116.30028684351728, 39.96869829817237], // 初始化地图中心点位置
                });

                this.map.add(maker)
            })
            .catch((e) => {
                console.log(e);
            });
    },
    destroyed() {
        this.map.destroy();

        this.map = null;
    }
}
</script>

<style lang="less" scoped>
.w1200 {
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 258px);

    .title {
        padding: 32px 0 16px;
    }

    .locationInfo {
        background: #FFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.03), 0px 0px 8px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        overflow: hidden;
        padding-bottom: 64px;

        .borderLine {
            width: 100%;
            height: 4px;
            background: linear-gradient(90deg, rgba(62, 179, 74, 0.60) 0%, rgba(73, 167, 61, 0.80) 100%);
        }

        >p {
            overflow: hidden;
            color: var(---1-, #131414);
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 0 40px;
        }

        .ads {
            margin: 24px 0 12px;
        }

        .email {
            margin-bottom: 24px;
        }

        .mapBox {
            width: 100%;
            height: 418px;
            background: #000;

            #map-container {
                width: 100%;
                height: 100%;

                :deep {
                    .amap-icon {
                        width: 33px !important;
                        height: 48px !important;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
</style>