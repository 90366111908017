<template>
    <div class="mainStayTunedBox">
        <!-- 尽情期待公共页面 -->
        <div class="tipsTit text-2xl">敬请期待</div>
        <div class="tips">内容建设中，暂未开放</div>
        <img src="/images/stayTuned.png" alt="" srcset="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.mainStayTunedBox {
    padding: 100px 0 206px;
    min-height: calc(100vh - 258px);
    text-align: center;

    .tipsTit {
        color: #131414;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .tips {
        color: #909399;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 24px;
    }

    >img{
        width: 460px;
        height: 280px;
        margin: 0 auto;
        object-fit: contain;
    }
}
</style>