<template>
    <div class="flex flex-col justify-center ">
        <div class="text-lg font-bold text-[#131414] mb-5 text-center">未来教育与创新发展专业委员会章程</div>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第一章 总则</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>
        <template>
            <div class="constitution-label">
                第一条 中国高技术产业发展促进会是由致力于高技术产业发展的企业、高等学校、科研院所及其他社会组织自愿结成的全国性、行业性社会团体。
            </div>
            <div class="constitution-label">
                本会简称高促会，英文名称为：China High-Tech Industry Promotion Society ,缩写为CHIPS。
            </div>
            <div class="constitution-label">
                本会会员分布和活动地域为全国。
            </div>
            <div class="constitution-label">
                第二条 本会的宗旨是：坚持“团结凝聚、合作创新、高效服务、共促发展”，搭建沟通交流平台，团结服务广大会员，为推动产业技术基础高级化和产业链现代化，构建现代产业体系，
                促进中国高技术产业健康发展做出贡献。
            </div>
            <div class="constitution-label">
                本会遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，弘扬爱国主义精神，遵守社会道德风尚，自觉加强诚信自律建设。
            </div>
            <div class="constitution-label">
                第三条 本会坚持中国共产党的全面领导，根据中国共产党章程的规定，
                设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。
            </div>
            <div class="constitution-label">
                本会的登记管理机关是民政部,党建工作机构是中央和国家机关工作委员会。
            </div>
            <div class="constitution-label">
                本会接受登记管理机关、党建工作机构、行业管理部门的业务指导和监督管理。
            </div>
            <div class="constitution-label">
                第四条 本会负责人包括理事长、副理事长、秘书长。
            </div>
            <div class="constitution-label">
                第五条 本会的住所设在北京市。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第二章 业务范围</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第六条 本会的业务范围：
            </div>
            <div class="constitution-label">
                (一) 组织开展高技术发展战略和政策研究；
            </div>
            <div class="constitution-label">
                (二) 组织开展高技术产业发展咨询服务；
            </div>
            <div class="constitution-label">
                (三) 根据国家相关规定，组织制定团体标准，参与制定高技术产业相关标准;
            </div>
            <div class="constitution-label">
                (四) 组织开展高技术产业相关培训；
            </div>
            <div class="constitution-label">
                (五) 受政府部门委托承办或根据市场和行业发展需要组织高技术产业发展相关论坛和展览；
            </div>
            <div class="constitution-label">
                (六) 组织国内外产学研交流与合作；
            </div>
            <div class="constitution-label">
                (七) 依照有关规定，编辑出版高技术产业相关期刊、报告；
            </div>
            <div class="constitution-label">
                (八) 依照有关规定，根据需要设立研究实体和经营实体；
            </div>
            <div class="constitution-label">
                (九) 促进高技术产业发展的其他事项。
            </div>
            <div class="constitution-label">
                业务范围中属于法律、法规等规定须经批准的事项，依法经批准后开展。
            </div>
        </template>


        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第三章 会员</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第七条 本会的会员为单位会员和个人会员。
            </div>

            <div class="constitution-label">
                第八条 拥护本会章程，符合下列条件的，可以自愿申请加入本会：
            </div>

            <div class="constitution-label">
                (一) 个人会员应在高技术产业相关领域有一定影响；
            </div>

            <div class="constitution-label">
                (二) 单位会员应是高技术产业发展相关企事业单位及其他社会组织。
            </div>

            <div class="constitution-label">
                本会不强制或者变相强制公民、法人或者其他组织加入本会。
            </div>

            <div class="constitution-label">
                第九条 会员入会程序是：
            </div>

            <div class="constitution-label">
                (一) 提交入会申请书；
            </div>

            <div class="constitution-label">
                (二) 经2名以上会员介绍；
            </div>

            <div class="constitution-label">
                (三) 提交有关证明材料，包括：
            </div>

            <div class="constitution-label">
                1.申请单位会员需提供法人证明文件复印件
            </div>

            <div class="constitution-label">
                2.申请个人会员需提供身份证复印件。
            </div>

            <div class="constitution-label">
                (四) 由理事会、常务理事会或者常务理事会授权机构讨论通过；
            </div>

            <div class="constitution-label">
                (五) 由本会理事会或其授权机构颁发会员证，并予以公告。
            </div>

            <div class="constitution-label">
                第十条 会员享有下列权利：
            </div>

            <div class="constitution-label">
                (一) 选举权、被选举权和表决权；
            </div>

            <div class="constitution-label">
                (二) 对本会工作的知情权、建议权和监督权；
            </div>

            <div class="constitution-label">
                (三) 参加本会活动并获得本会服务的优先权；
            </div>

            <div class="constitution-label">
                (四) 退会自由。
            </div>

            <div class="constitution-label">
                第十一条 会员履行下列义务：
            </div>

            <div class="constitution-label">
                (一) 遵守本会的章程和各项规定；
            </div>

            <div class="constitution-label">
                (二) 执行本会的决议；
            </div>

            <div class="constitution-label">
                (三) 按规定交纳会费；
            </div>

            <div class="constitution-label">
                (四) 维护本会的合法权益；
            </div>

            <div class="constitution-label">
                (五) 向本会反映情况，提供有关资料。
            </div>

            <div class="constitution-label">
                第十二条 会员如有违反法律、法规和本章程的行为，经理事会或者常务理事会表决通过，给予下列处分：
            </div>

            <div class="constitution-label">
                (一) 警告；
            </div>

            <div class="constitution-label">
                (二) 通报批评；
            </div>

            <div class="constitution-label">
                (三) 暂停行使会员权利；
            </div>

            <div class="constitution-label">
                (四) 除名。
            </div>

            <div class="constitution-label">
                第十三条 会员退会须书面通知本会并交回会员证。
            </div>

            <div class="constitution-label">
                第十四条 会员有下列情形之一的，自动丧失会员资格：
            </div>
            <div class="constitution-label">
                (一) 2年不按规定交纳会费；
            </div>

            <div class="constitution-label">
                (二) 2年不按要求参加本会活动；
            </div>

            <div class="constitution-label">
                (三) 不再符合会员条件；
            </div>
            <div class="constitution-label">
                (四) 丧失民事行为能力；
            </div>
            <div class="constitution-label">
                (五) 个人会员被剥夺政治权利。
            </div>

            <div class="constitution-label">
                第十五条 会员退会、被除名后或者自动丧失会员资格，其在本会相应的职务、权利、义务自行终止。
            </div>
            <div class="constitution-label">
                第十六条 本会置备会员名册，对会员情况进行记载。会员情况发生变动的，应当及时修改会员名册，并向会员公告。本会负责妥善保存会员相关档案，以及会员大会、理事会、常务理事会、监事会决议等原始记录。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第四章 组织机构</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第一节 会员大会</div>
        </div>

        <template>
            <div class="constitution-label">
                第十七条 会员大会是本会权力机构，其职权是：
            </div>

            <div class="constitution-label">
                (一) 制定和修改章程；
            </div>

            <div class="constitution-label">
                (二) 决定本会工作目标和发展规划等重大事项；
            </div>

            <div class="constitution-label">
                (三) 制定和修改理事、常务理事、负责人产生办法，报党建工作机构备案；
            </div>

            <div class="constitution-label">
                (四) 选举和罢免理事、监事;
            </div>

            <div class="constitution-label">
                (五) 制定和修改会费标准；
            </div>

            <div class="constitution-label">
                (六) 审议理事会的工作报告和财务报告；
            </div>

            <div class="constitution-label">
                (七) 决定名誉职务的设立；
            </div>

            <div class="constitution-label">
                (八)  审议监事会的工作报告；
            </div>

            <div class="constitution-label">
                (九) 决定名称变更事宜；
            </div>

            <div class="constitution-label">
                (十) 决定终止事宜；
            </div>

            <div class="constitution-label">
                (十一) 决定其他重大事宜。
            </div>

            <div class="constitution-label">
                第十八条 会员大会每5年召开1次。
            </div>

            <div class="constitution-label">
                本会召开会员大会，须提前15日将会议的议题通知会员
            </div>

            <div class="constitution-label">
                会员大会应当采用现场表决方式。
            </div>

            <div class="constitution-label">
                第十九条 经理事会或者本会二分之一以上的会员提议，应当召开临时会员大会。
            </div>

            <div class="constitution-label">
                临时会员大会由理事长主持。理事长不主持或不能主持的，由提议的理事会或1/5以上会员推举本会一名负责人主持。
            </div>

            <div class="constitution-label">
                第二十条 会员大会须有2/3以上的会员出席方能召开，决议事项符合下列条件方能生效：
            </div>

            <div class="constitution-label">
                (一) 制定和修改章程，决定本会终止，须经到会会员2/3以上表决通过；
            </div>

            <div class="constitution-label">
                (二) 选举理事，按得票数确定，但当选的得票数不得低于到会会员的1/2；
            </div>

            <div class="constitution-label">
                (三) 制定或修改会费标准，须经到会会员1/2以上无记名投票方式表决；
            </div>

            <div class="constitution-label">
                (四) 其他决议，须经到会会员1/2以上表决通过。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第二节 理事会</div>
        </div>

        <template>
            <div class="constitution-label">
                第二十一条 理事会是会员大会的执行机构，在会员大会闭会期间领导本会开展工作，对会员大会负责。
            </div>

            <div class="constitution-label">
                理事人数最多不得超过180人，且一般不超过会员的1/3。
            </div>
            <div class="constitution-label">
                理事不能来自同一会员单位，理事、常务理事不在本会领取薪酬。
            </div>

            <div class="constitution-label">
                本会理事应当符合以下条件：
            </div>
            <div class="constitution-label">
                (一) 坚持党的路线、方针、政策，政治素质好；
            </div>
            <div class="constitution-label">
                (二) 应在高技术产业发展的相关领域具有较大影响；
            </div>
            <div class="constitution-label">
                (三) 理事最高任职年龄不超过70周岁；
            </div>
            <div class="constitution-label">
                (四) 身体健康，能坚持正常工作；
            </div>
            <div class="constitution-label">
                (五) 具有完全民事行为能力。
            </div>
            <div class="constitution-label">
                第二十二条 理事的选举和罢免：
            </div>
            <div class="constitution-label">
                (一) 理事会换届，应当在会员大会召开前2个月，由理事会提名，成立由理事代表、监事代表、党组织代表和会员代表组成的换届工作领导小组(或专门选举委员会) ，负责换届选举工作；
            </div>
            <div class="constitution-label">
                理事会不能召集的，由1/5以上理事、监事会、本会党组织或党建联络员向党建工作机构申请，由党建工作机构会同行业管理部门、登记管理机关组织成立换届工作领导小组(或专门选举委员会) ，负责换届选举工作；
            </div>
            <div class="constitution-label">
                换届工作领导小组(或专门选举委员会) 拟定换届方案，应在会员大会召开前2个月报党建工作机构审核；换届工作中酝酿提名负责人人选，应当充分听取行业管理部门等方面意见，主动与党建工作机构沟通。
            </div>
            <div class="constitution-label">
                经党建工作机构同意，召开会员大会，选举和罢免理事；
            </div>
            <div class="constitution-label">
                (三) 根据会员大会的授权，理事会在届中可以增补、罢免部分理事，最高不超过原理事总数的1/5。
            </div>
            <div class="constitution-label">
                第二十三条 每个理事单位只能选派一名代表履行理事职责。单位调整理事代表，由其书面通知本会，报理事会或者常务理事会备案。该单位同时为常务理事的，其代表一并调整。
            </div>
            <div class="constitution-label">
                第二十四条 理事的权利：
            </div>
            <div class="constitution-label">
                (一) 理事会的选举权、被选举权和表决权；
            </div>
            <div class="constitution-label">
                (二) 对本会工作情况、财务情况、重大事项的知情权、建议权和监督权；
            </div>
            <div class="constitution-label">
                (三) 参与制定内部管理制度，提出意见建议；
            </div>
            <div class="constitution-label">
                (四) 向理事长或理事会提出召开临时会议的建议权。
            </div>
            <div class="constitution-label">
                第二十五条 理事应当遵守法律、法规和本章程的规定，忠实履行职责、维护本会利益，并履行以下义务：
            </div>
            <div class="constitution-label">
                (一) 出席理事会会议，执行理事会决议；
            </div>
            <div class="constitution-label">
                (二) 在职责范围内行使权利，不越权；
            </div>
            <div class="constitution-label">
                (三) 不利用理事职权谋取不正当利益；
            </div>
            <div class="constitution-label">
                (四) 不从事损害本会合法利益的活动；
            </div>
            <div class="constitution-label">
                (五) 不得泄露在任职期间所获得的涉及本会的保密信息，但法律、法规另有规定的除外;
            </div>
            <div class="constitution-label">
                (六) 谨慎、认真、勤勉、独立行使被合法赋予的职权；
            </div>
            <div class="constitution-label">
                (七) 接受监事对其履行职责的合法监督和合理建议。
            </div>
            <div class="constitution-label">
                第二十六条 理事会的职权是：
            </div>
            <div class="constitution-label">
                (一) 执行会员大会的决议；
            </div>
            <div class="constitution-label">
                (二) 选举和罢免常务理事、负责人，审议法定代表人变更事项；
            </div>
            <div class="constitution-label">
                (三) 决定名誉职务人选；
            </div>
            <div class="constitution-label">
                (四) 筹备召开会员大会，负责换届选举工作；
            </div>
            <div class="constitution-label">
                (五) 向会员大会报告工作和财务状况；
            </div>
            <div class="constitution-label">
                (六) 决定会员的吸收和除名；
            </div>
            <div class="constitution-label">
                (七) 决定设立、变更和终止分支机构、代表机构、办事机构和其他所属机构；
            </div>
            <div class="constitution-label">
                (八) 决定副秘书长、所属机构主要负责人；
            </div>
            <div class="constitution-label">
                (九) 领导本会所属机构开展工作；
            </div>
            <div class="constitution-label">
                (十) 审议年度工作报告和工作计划；
            </div>
            <div class="constitution-label">
                (十一) 审议年度财务预算、决算；
            </div>
            <div class="constitution-label">
                (十二) 制定信息公开办法、财务管理制度、分支机构、代表机构管理办法、内部矛盾解决办法、等重要的管理制度；
            </div>
            <div class="constitution-label">
                (十三) 决定本会负责人和工作人员的考核及薪酬管理办法；
            </div>
            <div class="constitution-label">
                (十四) 审议活动资金变更事项；
            </div>
            <div class="constitution-label">
                (十五) 审议住所变更事项；
            </div>
            <div class="constitution-label">
                (十六) 决定其他重大事项。
            </div>
            <div class="constitution-label">
                第二十七条 理事会每届5年。因特殊情况需提前或者延期换届的，须由理事会表决通过，经党建工作机构审核同意后，报登记管理机关批准。延期换届最长不超过1年。
            </div>
            <div class="constitution-label">
                第二十八条 理事会会议须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。
            </div>
            <div class="constitution-label">
                理事3次不出席理事会会议，自动丧失理事资格。
            </div>
            <div class="constitution-label">
                第二十九条 常务理事由理事会采取无记名投票方式从理事中选举产生。
            </div>
            <div class="constitution-label">
                负责人由会员大会或理事会采取无记名投票方式从理事或常务理事中选举产生，该负责人应同时为常务理事。
            </div>
            <div class="constitution-label">
                罢免常务理事、负责人，须经到会理事2/3以上投票通过。
            </div>
            <div class="constitution-label">
                第三十条  选举常务理事、负责人，按得票数确定当选人员，但当选的得票数不得低于总票数的2/3。
            </div>
            <div class="constitution-label">
                第三十一条  理事会每年至少召开1次会议，情况特殊的，可采用通讯形式召开。除视频会议外，其他通讯形式会议不得决定：负责人的调整。
            </div>
            <div class="constitution-label">
                第三十二条 经理事长或者1/5以上的理事提议，应当召开临时理事会会议。
            </div>
            <div class="constitution-label">
                理事长不能主持临时理事会会议的，由提议召集人推举本会1名负责人主持会议。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第三节 常务理事会</div>
        </div>

        <template>
            <div class="constitution-label">
                第三十三条 本会设立常务理事会。常务理事从理事中选举产生，人数不超过理事人数的1/3。在理事会闭会期间，常务理事会行使理事会第一、四、六、七、八、九、十、十一、十二、十三、十五项的职权，对理事会负责。
            </div>
            <div class="constitution-label">
                常务理事会与理事会任期相同，与理事会同时换届。
            </div>
            <div class="constitution-label">
                常务理事会会议须有2／3以上常务理事出席方能召开，其决议须经到会常务理事2／3以上表决通过方能生效。
            </div>
            <div class="constitution-label">
                常务理事3次不出席常务理事会会议，自动丧失常务理事资格。
            </div>
            <div class="constitution-label">
                第三十四条 常务理事会至少每6个月召开1次会议，情况特殊的，可采用通讯形式召开。
            </div>
            <div class="constitution-label">
                第三十五条 经理事长或1/3以上的常务理事提议，应当召开临时常务理事会会议。
            </div>
            <div class="constitution-label">
                理事长不能主持临时常务理事会会议，由提议召集人推举本会1名负责人主持会议。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第四节 负责人</div>
        </div>

        <template>
            <div class="constitution-label">
                第三十六条 本会负责人包括理事长1名，副理事长6-10名，秘书长1名。
            </div>
            <div class="constitution-label">
                本会负责人应当具备下列条件：
            </div>
            <div class="constitution-label">
                (一) 坚持中国共产党领导，拥护中国特色社会主义，坚决执行党的路线、方针、政策，具备良好的政治素质；
            </div>

            <div class="constitution-label">
                (二) 遵纪守法，勤勉尽职，个人社会信用记录良好；
            </div>
            <div class="constitution-label">
                (三) 具备相应的专业知识、经验和能力，熟悉行业情况，在本会业务领域有较大影响；
            </div>
            <div class="constitution-label">
                (四) 身体健康，能正常履责，最高任职年龄不超过70周岁，秘书长为专职；
            </div>
            <div class="constitution-label">
                (五) 具有完全民事行为能力；
            </div>
            <div class="constitution-label">
                (六) 能够忠实、勤勉履行职责，维护本会和会员的合法权益；
            </div>
            <div class="constitution-label">
                (七) 未被确认为失信被执行人；
            </div>
            <div class="constitution-label">
                (八) 无法律、法规、国家有关规定不得担任的其他情形。
            </div>

            <div class="constitution-label">
                理事长、秘书长不得兼任其他社会团体的理事长、秘书长，理事长和秘书长不得由同一人兼任，并不得来自于同一会员单位。
            </div>
            <div class="constitution-label">
                第三十七条 本会负责人任期与理事会相同，连任不超过2届。因特殊情况需要延长任期的，须经会员大会2/3以上会员表决通过，报党建工作机构审核同意并经登记管理机关批准后方可任职。
            </div>
            <div class="constitution-label">
                聘任(含向社会公开招聘) 的秘书长连任届次不受限制，可不经过民主选举程序。
            </div>
            <div class="constitution-label">
                第三十八条 理事长为本会法定代表人。
            </div>
            <div class="constitution-label">
                因特殊情况，经理事长推荐、理事会同意，报党建工作机构审核同意并经登记管理机关批准后，可以由副理事长或秘书长担任法定代表人。聘任(含向社会公开招聘) 的秘书长不得担任本会法定代表人。
            </div>
            <div class="constitution-label">
                法定代表人代表本会签署有关重要文件。
            </div>
            <div class="constitution-label">
                本会法定代表人不兼任其他社会团体的法定代表人。
            </div>
            <div class="constitution-label">
                第三十九条 担任法定代表人的负责人被罢免或卸任后，应当由本会在其被罢免或卸任后的20日内，报党建工作机构审核同意后，向登记管理机关办理变更登记。
            </div>
            <div class="constitution-label">
                原任法定代表人不予配合办理法定代表人变更登记的，本会可根据理事会同意变更的决议，报党建工作机构审核同意后，向登记管理机关申请变更登记。
            </div>

            <div class="constitution-label">
                第四十条 理事长履行下列职责：
            </div>
            <div class="constitution-label">
                (一) 召集和主持理事会、常务理事会；
            </div>
            <div class="constitution-label">
                (二) 检查会员大会、理事会、常务理事会决议的落实情况；
            </div>
            <div class="constitution-label">
                (三) 向会员大会、理事会、常务理事会报告工作；
            </div>

            <div class="constitution-label">
                (四) 提名所属机构主要负责人，交理事会或常务理事会决定。
            </div>
            <div class="constitution-label">
                理事长应每年向理事会进行述职。不能履行职责时，由其委托或理事会(或常务理事会) 推选一名副理事长代为履行职责。
            </div>

            <div class="constitution-label">
                第四十一条 副理事长、秘书长协助理事长开展工作。
            </div>
            <div class="constitution-label">
                秘书长行使下列职责：
            </div>
            <div class="constitution-label">
                (一) 协调各机构开展工作；
            </div>

            <div class="constitution-label">
                (二) 主持办事机构开展日常工作；
            </div>
            <div class="constitution-label">
                (三) 提名副秘书长，交理事会或者常务理事会决定；
            </div>
            <div class="constitution-label">
                (四) 决定专职工作人员的聘用；
            </div>
            <div class="constitution-label">
                (五) 拟订年度工作报告和工作计划，报理事会或常务理事会审议；
            </div>
            <div class="constitution-label">
                (六) 拟订年度财务预算、决算报告，报理事会或常务理事会审议；
            </div>
            <div class="constitution-label">
                (七) 处理其他日常事务。
            </div>

            <div class="constitution-label">
                第四十二条 会员大会、理事会、常务理事会会议应当制作会议纪要。形成决议的，应当制作书面决议，理事会、常务理事会决议同时由出席会议成员确认。会议纪要、会议决议应当以适当方式向会员通报并备会员查询，并至少保存30年。
            </div>
            <div class="constitution-label">
                理事、常务理事、负责人的选举结果应当及时向会员通报并备会员查询。负责人的选举结果须在20日内报党建工作机构审核，经同意，报登记管理机关备案。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第五节 监事会</div>
        </div>

        <template>
            <div class="constitution-label">
                第四十三条 本会设立监事会，监事任期与理事任期相同，期满可以连任。监事会由 3 名监事组成。监事会设监事长1名，副监事长1名，由监事会推举产生。监事长和副监事长最高任职年龄不超过70周岁，连任不超过2届。
            </div>
            <div class="constitution-label">
                本会接受并支持委派监事的监督指导。
            </div>
            <div class="constitution-label">
                第四十四条 监事的选举和罢免：
            </div>
            <div class="constitution-label">
                (一) 由会员大会选举产生；
            </div>
            <div class="constitution-label">
                (二) 监事的罢免依照其产生程序。
            </div>
            <div class="constitution-label">
                第四十五条 本会的负责人、理事、常务理事和财务管理人员不得兼任监事。
            </div>
            <div class="constitution-label">
                第四十六条 监事会行使下列职权：
            </div>
            <div class="constitution-label">
                (一) 列席理事会、常务理事会会议，并对决议事项提出质询或建议；
            </div>
            <div class="constitution-label">
                (二) 对理事、常务理事、负责人执行本会职务的行为进行监督，对严重违反本会章程或者会员大会决议的人员提出罢免建议；
            </div>

            <div class="constitution-label">
                (三) 检查本会的财务报告，向会员大会报告监事会的工作和提出提案；
            </div>
            <div class="constitution-label">
                (四) 对负责人、理事、常务理事、财务管理人员损害本会利益的行为，要求其及时予以纠正；
            </div>
            <div class="constitution-label">
                (五) 向党建工作机构、行业管理部门、登记管理机关以及税务、会计主管部门反映本会工作中存在的问题；
            </div>
            <div class="constitution-label">
                (六) 决定其他应由监事会审议的事项。
            </div>
            <div class="constitution-label">
                监事会每 6个月至少召开1次会议。监事会会议须有2/3以上监事出席方能召开，其决议须经到会监事1/2以上通过方为有效。
            </div>
            <div class="constitution-label">
                第四十七条 监事应当遵守有关法律、法规和本章程，忠实、勤勉履行职责
            </div>
            <div class="constitution-label">
                第四十八条 监事会可以对本会开展活动情况进行调查；必要时，可以聘请会计师事务所等协助其工作。监事会行使职权所必需的费用，由本会承担。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第六节 分支机构、代表机构</div>
        </div>

        <template>
            <div class="constitution-label">
                第四十九条 本会可以按照国家有关规定在本会的宗旨和业务范围内，按照确有工作需要且与本会管理能力相适应的原则设立分支机构、代表机构。本会的分支机构依据会员组成特点、业务范围的划分等设立，代表机构依据本会授权在规定地域内代表本会开展联络、交流、调研活动。本会的分支机构、代表机构是本会的组成部分，不具有法人资格，不得另行制订章程，不得发放任何形式的登记证书，按照本章程规定的宗旨和业务范围，在本会授权的范围内开展活动，法律责任由本会承担。
            </div>
            <div class="constitution-label">
                第五十条 本会不设立地域性分支机构，不在分支机构、代表机构下再设立分支机构、代表机构。
            </div>
            <div class="constitution-label">
                第五十一条 本会分支机构名称以“分会”、“专业委员会”、“工作委员会”、“专项基金管理委员会”等字样结束，代表机构名称以“代表处”、“办事处”等字样结束。分支机构、代表机构名称不以各类法人组织的名称命名，不在名称中冠以“中国”、“中华”、“全国”、“国家”等字样，对外开展活动，应当使用冠有本会名称的规范全称。
            </div>
            <div class="constitution-label">
                第五十二条 分支机构、代表机构负责人的最高任职年龄不得超过70周岁，连任不超过2届。
            </div>
            <div class="constitution-label">
                第五十三条 分支机构、代表机构的财务必须纳入本会法定账户统一管理，全部收支应当纳入本会财务统一核算。
            </div>
            <div class="constitution-label">
                第五十四条 本会在年度工作报告中将分支机构、代表机构的有关情况报送登记管理机关。同时，将有关信息及时向社会公开，自觉接受社会监督。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class=" leading-8 text-label-color mx-auto ">第七节 内部管理制度和矛盾解决机制</div>
        </div>
        <template>
            <div class="constitution-label">
                第五十五条 本会建立各项内部管理制度，完善相关管理规程。建立《会员管理办法》、《会费管理办法》、《理事会选举规程》、《会员大会选举规程》、《信息公开办法》、《财务管理制度》、《分支机构、代表机构管理办法》、《内部矛盾解决办法》等相关制度和文件。
            </div>
            <div class="constitution-label">
                第五十六条 本会建立健全证书、印章、档案、文件等内部管理制度，并将以上物品和资料妥善保管于本会场所，任何单位、个人不得非法侵占。管理人员调动工作或者离职时，必须与接管人员办清交接手续。
            </div>
            <div class="constitution-label">
                第五十七条 本会证书、印章遗失时，经理事会2/3以上理事表决通过，在公开发行的报刊上刊登遗失声明，按规定申请重新制发或刻制。如被个人非法侵占，应通过法律途径要求返还。
            </div>
            <div class="constitution-label">
                第五十八条 本会建立民主协商和内部矛盾解决机制。如发生内部矛盾不能经过协商解决的，可以通过调解、诉讼等途径依法解决。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第五章 资产管理、使用原则</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第五十九条 本会收入来源：
            </div>
            <div class="constitution-label">
                (一) 会费；
            </div>
            <div class="constitution-label">
                (二) 捐赠；
            </div>
            <div class="constitution-label">
                (三) 政府资助；
            </div>
            <div class="constitution-label">
                (四) 在核准的业务范围内开展活动、提供服务的收入；
            </div>
            <div class="constitution-label">
                (五) 利息；
            </div>
            <div class="constitution-label">
                (六) 其他合法收入。
            </div>
            <div class="constitution-label">
                第六十条 本会按照国家有关规定收取会员会费。
            </div>
            <div class="constitution-label">
                本会经批准开展评比达标表彰等活动，不收取任何费用。
            </div>
            <div class="constitution-label">
                第六十一条 本会的收入除用于与本会有关的、合理的支出外，全部用于本章程规定的业务范围。
            </div>
            <div class="constitution-label">
                第六十二条 本会执行《民间非营利组织会计制度》，建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。
            </div>
            <div class="constitution-label">
                第六十三条 本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员应当进行会计核算，实行会计监督。会计人员调动工作或者离职时，必须与接管人员办清交接手续。
            </div>
            <div class="constitution-label">
                第六十四条 本会的资产管理执行国家规定的财务管理制度，接受会员大会和有关部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
            </div>
            <div class="constitution-label">
                第六十五条 本会重大资产配置、处置须经过会员大会或者理事会(常务理事会) 审议。
            </div>
            <div class="constitution-label">
                第六十六条 理事会(常务理事会) 决议违反法律、法规或本章程规定，致使本会遭受损失的，参与审议的理事(常务理事) 应当承担责任。但经证明在表决时反对并记载于会议记录的，该理事(常务理事) 可免除责任。
            </div>
            <div class="constitution-label">
                第六十七条 本会换届或者更换法定代表人之前应当进行财务审计。
            </div>
            <div class="constitution-label">
                法定代表人在任期间，本会发生违反《社会团体登记管理条例》和本章程的行为，法定代表人应当承担相关责任。因法定代表人失职，导致本会发生违法行为或造成财产损失的，法定代表人应当承担个人责任。
            </div>
            <div class="constitution-label">
                第六十八条 本会的全部资产及其增值为本会所有，任何单位、个人不得侵占、私分和挪用，也不得在会员中分配。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第六章 信息公开与信用承诺</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第六十九条 本会依据有关法规政策，履行信息公开义务，建立信息公开制度，及时向会员公开年度工作报告、第三方机构出具的报告、会费收支情况以及经理事会研究认为有必要公开的其他信息，及时向社会公开登记事项、章程、组织机构、接受捐赠、信用承诺、承接政府转移或委托事项、可提供服务事项及运行情况等信息。
            </div>
            <div class="constitution-label">
                第七十条 本会建立新闻发言人制度，经理事会或常务理事会通过，任命或指定1名负责人作为新闻发言人，就本组织的重要活动、重大事件或热点问题，通过定期或不定期举行新闻发布会、吹风会、接受采访等形式主动回应社会关切。新闻发布内容应由本会法定代表人或主要负责人审定，确保正确的舆论导向。
            </div>
            <div class="constitution-label">
                第七十一条 本会建立年度报告制度，年度报告内容及时向社会公开，接受公众监督。
            </div>
            <div class="constitution-label">
                第七十二条 本会重点围绕服务内容、服务方式、服务对象和收费标准等建立信用承诺制度，并向社会公开信用承诺内容。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第七章 章程的修改程序</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第七十三条 对本会章程的修改，由理事会表决通过，提交会员大会审议。
            </div>
            <div class="constitution-label">
                第七十四条 本会修改的章程，经会员大会到会会员2/3以上表决通过后，报党建工作机构审核，经同意，在30日内报登记管理机关核准。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第八章 终止程序及终止后的财产处理</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第七十五条 本会终止动议由理事会或者常务理事会提出，报会员大会表决通过。
            </div>
            <div class="constitution-label">
                第七十六条 本会终止前，应当依法成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。
            </div>
            <div class="constitution-label">
                第七十七条 本会清算后的剩余财产，在党建工作机构和登记管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业，或者捐赠给宗旨相近的社会组织。
            </div>
            <div class="constitution-label">
                第七十八条 本会经登记管理机关办理注销登记手续后即为终止。
            </div>
        </template>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">第九章　附则</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <template>
            <div class="constitution-label">
                第七十九条 本章程经2023年3月19日第五次会员大会表决通过。
            </div>
            <div class="constitution-label">
                第八十条 本章程的解释权属本会的理事会。
            </div>
            <div class="constitution-label">
                第八十一条 本章程自登记管理机关核准之日起生效。
            </div>
        </template>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.constitution-label {
    text-align: justify;
    line-height: 32px;
    color: #606266;
}
</style>