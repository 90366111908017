import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/HomeView.vue'
import organization from '@/views/organizationIntroduction/index.vue'
import organizationIntroduce from '@/views/organizationIntroduction/introduce.vue'
import organizationTeam from '@/views/organizationIntroduction/team.vue'
import organizationMember from '@/views/organizationIntroduction/member.vue'
import organizationConstitution from '@/views/organizationIntroduction/constitution.vue'
import dynamicNews from '@/views/dynamicNews/index.vue'
import mainDetail from '@/views/mainDetail.vue' // 统一的详情页面
import policyRead from '@/views/policyRead/index.vue'
import searchNewsVue from '@/views/searchNews.vue'
import themeActivity from '@/views/themeActivity/index.vue'
import stayTuned from '@/views/stayTuned.vue'
import contactUs from '@/views/contactUs/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/organization',
    name: 'organization',
    component: organization,
    redirect: '/organization/introduce',
    children: [{
      path: '/organization/introduce',
      name: 'introduce',
      component: organizationIntroduce
    }, {
      path: '/organization/team',
      name: 'team',
      component: organizationTeam
    }, {
      path: '/organization/member',
      name: 'member',
      component: organizationMember
    }, {
      path: '/organization/constitution',
      name: 'constitution',
      component: organizationConstitution
    }]
  },
  {
    path: '/dynamicNews',
    name: '新闻动态',
    component: dynamicNews
  },
  {
    path: '/dynamicNewsDetail',
    name: '新闻动态详情',
    component: mainDetail
  },

  {
    path: '/policyRead',
    name: '政策解读',
    component: policyRead
  },
  {
    path: '/policyReadDetail',
    name: '政策解读详情',
    component: mainDetail
  },
  {
    path: '/searchNews',
    name: '搜索新闻',
    component: searchNewsVue,
    meta: {
      canJump: true
    }
  },
  {
    path: '/themeActivity',
    name: '主题活动',
    component: themeActivity
  },
  {
    path: '/themeActivityDetail',
    name: '主题活动详情',
    component: mainDetail
  },
  {
    path: '/innovate',
    name: '创新引领',
    component: stayTuned
  },
  {
    path: '/member',
    name: '会员转区',
    component: stayTuned
  },
  {
    path: '/contactUs',
    name: '联系我们',
    component: contactUs
  }
]

const router = new VueRouter({
  routes
});


// router.beforeEach(async (to, from, next) => {
// });

export default router
