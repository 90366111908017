<template>
    <div class="dynamic-news-wrapper flex flex-col pt-8 pb-16 max-w-[1200px] mx-auto">
        <div class="text-2xl font-bold text-[#1D2129] mb-4">新闻动态</div>

        <div class="flex-1 flex flex-col h-full px-10 pt-1 pb-10 border-t-4 border-[#49A73D] shadow-md ">
            <div class="flex-1 flex flex-col h-full">
                <div class=" relative flex py-5 cursor-pointer hover:opacity-80" v-for="(item, index) in newsList" :key="index" @click="onClickNewsDetail(item)">
                    <!-- pictrue -->
                    <div class="w-40 h-[90px] mr-4 ">
                        <img :src="item.Url || '/images/img_news-default.png'" alt="" class="w-full h-full rounded-sm">
                    </div>
                    <!-- descripe -->
                    <div class="flex-1 flex flex-col overflow-hidden">
                        <!-- descripeTop -->
                        <div class="flex justify-between items-center w-full mb-[14px]">
                            <div class="flex-3 font-bold text-base text-[#131414] overflow-hidden text-ellipsis whitespace-nowrap">{{ item.Name }}</div>

                            <div class="flex-1 text-right text-[#909399] text-[13px] leading-[22px]">{{ item.UpdateTime }}</div>
                        </div>
                        <!-- descripeBottom -->
                        <div class="content text-base text-[#606266]">
                            {{ item.ContentReal }}
                        </div>
                    </div>

                    <div class="absolute bottom-0 opacity-50 w-full border-t-2 border-t-[#49A73D] border-dotted "></div>
                </div>
            </div>

            <pagenation class="mt-8" v-if="newsList.length > 0" :totalCount="pagenationConfig.totalCount" :pageCount="pagenationConfig.pageCount" @onChangePage="onChangePage" />

            <div class="absolute top-1/2 left-1/2 -translate-x-1/2" v-else>暂无数据</div>
        </div>
    </div>
</template>

<script>
import pagenation from '@/components/pagenation.vue';

import { GetZoneNewsColumn, GetZoneNewsEditListJson } from '@/request/api';

export default {
    components: {
        pagenation
    },
    data() {
        return {
            newsList: [],
            pagenationConfig: {
                currentPage: 1,
                totalCount: 0,
                pageCount: 10
            },
            xwData: {}
        }
    },
    methods: {
        toGetZoneNewsColumn() {
            GetZoneNewsColumn().then(res => {
                let result = res ? res.result : [];
                if (result && result.length > 0) {
                    let hdItm = result.find(r => /新闻/.test(r.Name));

                    this.hdData = hdItm;

                    // 获取活动
                    this.getDynamicNewsData(hdItm.Idx, this.pagenationConfig.pageCount, 'newsList');
                }
            });
        },
        getDynamicNewsData(columnIdx, count, dataName) {
            let sdt = {
                "session": "",
                "page": 1,
                count,
                "index": this.pagenationConfig.currentPage,
                "cidx": columnIdx,
                "ndisable": 0,
                "lencontent": 400

            };
            GetZoneNewsEditListJson(sdt).then(res => {
                if (res && res.result) {
                    let child = res.result[2].ChildList;
                    if (child && dataName) {
                        this[dataName] = child;

                        this.pagenationConfig.totalCount = res.result[0].Total;
                    }
                }
            });
        },
        onClickNewsDetail(item) {
            this.$router.push(`/dynamicNewsDetail?idx=${item.Idx}`)
        },
        // 分页切换
        onChangePage(pageIndex) {
            this.pagenationConfig.currentPage = pageIndex;

            this.getDynamicNewsData(this.hdData.Idx, this.pagenationConfig.pageCount, 'newsList');
        }
    },
    mounted() {
        this.toGetZoneNewsColumn();
    }
}
</script>
<style scoped>
.dynamic-news-wrapper {
    min-height: calc(100vh - 258px);
}

.content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>