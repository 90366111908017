<template>
  <div id="app">
    <Loading />

    <Mainnav></Mainnav>
    <router-view />
    <Mainfooter></Mainfooter>
  </div>
</template>

<script>
import Mainnav from '@/components/Mainnav.vue';
import Mainfooter from '@/components/Mainfooter.vue';
import Loading from './components/Loading.vue';

export default {
  components: {
    Mainnav,
    Mainfooter,
    Loading
}
}
</script>

<style lang="less">
*{
  padding: 0;
  margin: 0;
}
</style>
