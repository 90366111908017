<template>
    <div>
        <div class="mainNav">
            <div class="navContent">
                <div class="logo"></div>

                <div class="searchInput flex items-center rounded-md select-none">
                    <input v-model="keyWord" class="focus:outline-none focus:ring focus:ring-violet-300" type="text"
                        placeholder="请输入关键字查询" @keydown.enter="toSearch">
                    <div class="searchBtn hover:bg-green-600 active:bg-green-700 flex justify-center items-center cursor-pointer"
                        @click="toSearch">
                        <img src="/images/ic_search.png" />
                    </div>
                </div>
            </div>
        </div>

        <div class="routeList">
            <div class="w1200 flex items-center justify-between">
                <div class="flex-1 routeItem hover:bg-green-600 active:bg-green-700 cursor-pointer transition-colors"
                    v-for="(item, index) in routeList" :key="index" :class="{ active: activeIndex == index }"
                    @click="pageChange(item, index)">
                    <div>{{ item.name }}</div>

                    <div class="borderLine" v-if="activeIndex == index"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routeList: [
                {
                    name: '首页',
                    path: '/'
                },
                {
                    name: '组织介绍',
                    path: '/organization'
                },
                {
                    name: '新闻动态',
                    path: '/dynamicNews'
                },
                {
                    name: '政策解读',
                    path: '/policyRead'
                },
                {
                    name: '主题活动',
                    path: '/themeActivity'
                },
                {
                    name: '创新引领',
                    path: '/innovate'
                },
                {
                    name: '成果转化',
                    path: process.env.VUE_APP_CROUSE_PAGE_URL + '/'
                },
                {
                    name: '会员专区',
                    path: '/member'
                },
                {
                    name: '联系我们',
                    path: '/contactUs'
                },
            ],

            activeIndex: 0,

            keyWord: ''
        }
    },
    watch: {
        $route() {
            this.changeActiveIndex();
        }
    },
    methods: {
        toSearch() {
            if (!this.keyWord) {
                return
            }
            // 保存搜索的信息
            sessionStorage.setItem('searchKeyWord', this.keyWord);

            // 若当前页面在搜索也
            if (/searchNews/.test(this.$route.path)) {
                window.mainToSearchMethod && window.mainToSearchMethod();
            } else {
                this.$router.push('/searchNews');
            }
        },
        // 页面切换
        pageChange(item, index) {
            let canJump = this.$route.meta.canJump;
            if ((index !== this.activeIndex && item.path) || canJump) {
                if (/^http/.test(item.path)) {
                    window.open(item.path);
                } else {
                    this.$router.push(item.path);
                    this.activeIndex = index;
                }

            }
        },
        changeActiveIndex() {
            // 初始进入时，判断当前应该选中的选中项（默认第一个）
            let path = this.$route.path; // 当前路径
            // 匹配的时候先排除调首页（首页路径比较特殊），如果都没匹配到则默认首页
            let idx = this.routeList.filter(r => r.path !== '/' && !!r.path).findIndex(r => {
                let reg = new RegExp(`^${r.path}`);
                // console.log(r.path, path, reg.test(path));
                return reg.test(path);

            });
            if (idx > -1) {
                this.activeIndex = idx + 1; // 因为排除了首页，所以要加1
            } else {
                this.activeIndex = 0;
            }
        }
    },
    created() {
        this.changeActiveIndex();
    }
}
</script>

<style lang="less" scoped>
.routeList {
    width: 100%;
    background: #49A73D;

    color: #FFF;
    text-align: center;
    font-size: 16px;

    .routeItem {
        height: 48px;
        line-height: 48px;
        position: relative;

        &.active {
            background-color: #2C921F;
        }

        &:hover {
            background-color: #2C921F;
        }
    }

    .borderLine {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(270deg, rgba(166, 225, 159, 0.00) 0%, #B3EDAC 51.26%, rgba(166, 225, 159, 0.00) 100%);
    }

    >.w1200 {
        max-width: 1200px;
        height: 48px;
        margin: 0 auto;
    }
}

.searchBtn {
    width: 48px;
    height: 34px;
    background-color: #49A73D;

    >img {
        width: 18px;
        height: 18px;
    }
}

.mainNav {
    width: 100%;
    height: 80px;
    background-image: url('/public/images/img_top_nav.png');

    .searchInput {
        border: 1px solid rgba(73, 167, 61, 0.40);
        overflow: hidden;

        input {
            width: 192px;
            height: 34px;
            padding: 0 12px;
            outline: none;
            font-size: 14px;
            color: #131414;
        }
    }

    .navContent {
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 440px;
            height: 40px;
            background-image: url('/public/images/img_logo.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
</style>