import { render, staticRenderFns } from "./Mainnav.vue?vue&type=template&id=fd074a08&scoped=true"
import script from "./Mainnav.vue?vue&type=script&lang=js"
export * from "./Mainnav.vue?vue&type=script&lang=js"
import style0 from "./Mainnav.vue?vue&type=style&index=0&id=fd074a08&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd074a08",
  null
  
)

export default component.exports