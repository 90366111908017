<template>
  <div class="homeView">
    <div class="w1200">
      <mainTitle title="新闻动态" morePath="/dynamicNews"></mainTitle>
      <bannerList :list="bannerDataList"></bannerList>
      <mainTitle title="政策解读" morePath="/policyRead"></mainTitle>
      <div class="flex justify-between mainBox">
        <menuList class="flex-1" :list="policyDataList.slice(0, 5)" detailPath="/policyReadDetail"></menuList>
        <menuList class="flex-1" :list="policyDataList.slice(5)" detailPath="/policyReadDetail"></menuList>

        <div class="spaceLine"></div>
      </div>
    </div>
    <div class="bannerTips flex items-center justify-center">
      <img class="text" src="/images/img_banner_text.png" />
    </div>

    <div class="w1200">
      <div class="flex justify-between mainBox">
        <div class="flex-1">
          <mainTitle title="组织介绍" morePath="/organization"></mainTitle>
          <div class="desc">
            未来教育与创新发展专业委员会的主要核心任务是从未来信息社会劳动力的需求与人的个性化与全面发展出发，
            围绕高质量的教育和教育公平两大核心目标，探讨未来社会环境下的教育战略与政策、理念与模式，教育的组织结构和教育环境的重组，
            以及人才培养模式等。其业务范围包括研究未来教育的发展脉络、核心要素、国际动态与发展趋势，探索未来教育转型、创新、变革的发展路径；
            举办未来教育相关的学术会议和专题研讨会、报告会、座谈会等，题研讨会、报告会、座谈会等，题研讨会，研讨总结...
          </div>
        </div>
        <div class="flex-1">
          <mainTitle title="主题活动" morePath="/themeActivity"></mainTitle>
          <menuList iconType="cir" :list="activityList" detailPath="/themeActivityDetail"></menuList>
        </div>
      </div>
    </div>

    <div class="creativeLeader">
      <div class="w1200">
        <mainTitle title="创新引领" morePath="/innovate"></mainTitle>

        <div class="flex items-center justify-between">
          <div class="createiveItem">
            <div class="title">
              <p class="enTit">wurenjijiaoyu</p>
              <p class="tit">无人机教育</p>
            </div>
            <img src="/images/creative_img1.png" />
          </div>
          <div class="createiveItem">
            <div class="title">
              <p class="enTit">wurenjijiaoyu</p>
              <p class="tit">无人机教育</p>
            </div>
            <img src="/images/creative_img2.png" />
          </div>
          <div class="createiveItem">
            <div class="title">
              <p class="enTit">wurenjijiaoyu</p>
              <p class="tit">无人机教育</p>
            </div>
            <img src="/images/creative_img3.png" />
          </div>
          <div class="createiveItem">
            <div class="title">
              <p class="enTit">wurenjijiaoyu</p>
              <p class="tit">无人机教育</p>
            </div>
            <img src="/images/creative_img4.png" />
          </div>
          <div class="createiveItem">
            <div class="title">
              <p class="enTit">wurenjijiaoyu</p>
              <p class="tit">无人机教育</p>
            </div>
            <img src="/images/creative_img5.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="w1200">
      <div class="mainBox2" style="overflow:unset">
        <mainTitle title="成果转化" :morePath="coursePageUrl">
          <div slot="left" class="flex items-center corseTypeList">
            <div v-for="( item, index ) in  courseTypeList " :key="'courseType_' + index"
              class="corseTypeItem cursor-pointer" :class="{ active: courseTypeActiveIdx == index }"
              @click="getCourseList(index)">{{ item.categoryName
              }}</div>
          </div>
        </mainTitle>
        <div class="flex justify-between" v-if="!courseLoading">
          <a class="courseItem cursor-pointer block"
            :href="coursePageBaseUrl + `/Wdkc/Kcjs?typeId=${courseTypeList[courseTypeActiveIdx].categoryId}&from=SubjectCurriculum&Ctype=detail&id=${item.id}&courseId=${item.courseId}&sourceCode=forclass`"
            target="_blank" v-for="( item, index ) in  courseList " :key="'course_' + index">
            <img :src="item.courseCover" />
            <p class="tit">{{ item.courseName }}</p>
          </a>
        </div>
        <div class="loadingTips" v-else>
          课程信息加载中...
        </div>
      </div>
      <mainTitle title="合作链接" noMore></mainTitle>
      <div class="flex flex-wrap collaLink">
        <div class="flex items-center">
          <img class="icon" src="/images/coll_icon.png" />
          <a href="http://www.chips.ac.cn/" target="_blank">中国高技术产业发展促进会</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mainTitle from './module/mainTitle.vue';

import bannerList from './module/bannerList.vue';
import menuList from './module/menuList.vue';

import { courseQueryFilter, coursePublicCourse, GetZoneNewsColumn, GetZoneNewsEditListJson } from '@/request/api';
export default {
  name: 'HomeView',
  components: {
    mainTitle,
    bannerList,
    menuList,
  },
  data() {
    return {
      // 轮播新闻的参数
      bannerDataList: [],
      // 政策列表
      policyDataList: [],
      // 活动列表
      activityList: [],

      // 课程类型列表
      courseLoading: false, // 是否处于加载中
      courseTypeList: [],
      courseTypeActiveIdx: 0,
      // 课程列表
      courseList: [],
      coursePageBaseUrl: process.env.VUE_APP_CROUSE_PAGE_URL,
    }
  },
  computed: {
    // 课程跳转的地址
    coursePageUrl() {
      let baseUrl = this.coursePageBaseUrl + '/SubjectCurriculum'
      let item = this.courseTypeList[this.courseTypeActiveIdx];
      if (item && item.categoryId) {
        return baseUrl + '?typeId=' + item.categoryId;
      } else {
        return baseUrl;
      }
    }
  },
  methods: {
    // 
    toGetZoneNewsColumn() {
      GetZoneNewsColumn().then(res => {
        let result = res ? res.result : [];
        if (result && result.length > 0) {
          let newItm = result.find(r => /新闻/.test(r.Name));
          let zcItm = result.find(r => /政策/.test(r.Name));
          let hdItm = result.find(r => /活动/.test(r.Name));
          // 获取新闻
          this.getNewsByIdx(newItm.Idx, 10, 'bannerDataList');
          // 获取政策
          this.getNewsByIdx(zcItm.Idx, 10, 'policyDataList');
          // 获取活动
          this.getNewsByIdx(hdItm.Idx, 6, 'activityList');
        }
      });
    },

    getNewsByIdx(columnIdx, count, dataName) {
      let sdt = {
        "session": "",
        "page": 1,
        count,
        "index": 1,
        "cidx": columnIdx,
        "ndisable": 0,
        "lencontent": 100

      };
      GetZoneNewsEditListJson(sdt).then(res => {
        if (res && res.result) {
          let child = res.result[2].ChildList;
          if (child && dataName) {
            this[dataName] = child;
          }
        }
      });
    },

    // 获取当前下课程展示列表
    getCourseList(index) {
      if (typeof index === 'number') {
        // 若点击的是已选中的不请求列表接口
        if (index === this.courseTypeActiveIdx) {
          return
        }
        this.courseTypeActiveIdx = index; // 切换下标
      }
      let item = this.courseTypeList[this.courseTypeActiveIdx];
      if (item) {
        let sdt = {
          "tag": item.categoryName,
          "keyWord": "",
          "pageIndex": 1,
          "pageSize": 4, // 这里只展示4个
        };

        this.courseLoading = true;
        coursePublicCourse(sdt).then(res => {
          this.courseLoading = false;
          if (res.data && res.data.items) {
            this.courseList = res.data.items || [];
          } else {
            this.courseList = [];
          }
        });
      }
    }
  },
  mounted() {
    this.courseLoading = true;
    courseQueryFilter().then(res => {
      if (res.status && res.status.success) {
        this.courseTypeList = (res.data || []).filter(r => r.categoryName != '其它课程'); // 展示除“其他课程”的课程
        this.getCourseList(true);
      } else {
        this.courseLoading = false;
      }
    });

    this.toGetZoneNewsColumn();
  }
}
</script>

<style lang="less" scoped>
.w1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.loadingTips {
  text-align: center;
  color: #131414;
  font-size: 14px;
  padding: 40px 0;
}

.corseTypeList {
  margin-left: 40px;

  .corseTypeItem {
    min-width: 88px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 12px;

    color: #606266;
    font-size: 16px;
    padding: 0 12px;

    &.active {
      border-radius: 2px;
      background: rgba(68, 161, 56, 0.15);
      color: #379A2A;
      font-weight: 700;
    }
  }
}

.collaLink {
  padding-top: 16px;
  padding-bottom: 32px;

  >div {
    margin-right: 88px;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  a {
    color: #131414;
    font-size: 16px;
    line-height: 28px;

    &:hover {
      color: #44A138;
    }
  }
}

.courseItem {
  width: 288px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.02), 0px 4px 12px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  margin-top: 16px;

  border-radius: 4px;

  img {
    width: 100%;
    height: 162px;
    object-fit: cover;
  }

  .tit {
    color: #131414;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }
}

.createiveItem {
  background-color: #ffffff;
  width: 224px;
  border-radius: 4px;
  margin-top: 16px;

  overflow: hidden;

  img {
    width: 100%;
    height: 140px;
    object-fit: contain;
  }

  .title {
    padding: 32px 24px;

    .enTit {
      color: #C0C4CC;
      font-size: 12px;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }

    .tit {
      color: #131414;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}


.homeView {
  padding: 32px 0;
  background: #ffffff;

  .creativeLeader {
    width: 100%;
    height: 400px;
    background-image: url('/public/images/img_creative_bg.png');
    background-size: auto 100%;
    background-position: center;

    padding: 36px 0 0 0;
    box-sizing: border-box;
  }

  .bannerTips {
    width: 100%;
    height: 120px;
    background-image: url('/public/images/img_banner.png');
    background-size: auto 100%;
    background-position: center;

    margin-bottom: 48px;

    .text {
      width: 902px;
      height: 72px;
    }
  }


  .mainBox2 {
    margin-top: 48px;
  }

  .mainBox,
  .mainBox2 {
    margin-bottom: 48px;
    position: relative;
    overflow: hidden;

    .desc {
      color: #131414;
      text-align: justify;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 16px;
    }

    >.spaceLine {
      width: 0.5px;
      height: 194px;
      background-image: url('/public/images/space_line.png');
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .mainBox>div:nth-of-type(1) {
    padding-right: 30px;
  }

  .mainBox>div:nth-of-type(2) {
    padding-left: 30px;
  }
}
</style>
