import axiosInstance from "./http";

const env = process.env.VUE_APP_ENV; // 当前环境

// 获取课程导航列表
export function courseQueryFilter(params) {
    return axiosInstance({
        url: process.env.VUE_APP_CROUSE_REQUEST_URL + "/OpenCourse/api/v1/PublicCourse/QueryFilter",
        method: "get",
        // headers: {
        //     "Content-Type": "application/json",
        // },
        params,
    });
}

// 获取课程下的课程列表
export function coursePublicCourse(params) {
    return axiosInstance({
        url: process.env.VUE_APP_CROUSE_REQUEST_URL + "/OpenCourse/api/v1/PublicCourse",
        method: "post",
        data: params,
    });
}

// 新闻搜索部分接口
export function newsGetZoneNewsSearch(params) {

    return axiosInstance({
        url: process.env.VUE_APP_WS_API_URL + "/PaasService.asmx/GetZoneNewsSearch",
        method: "post",
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
        data: params,
    });
}


// 官网获取模块列表的接口
export function GetZoneNewsColumn() {
    let params = {
        session: '',
        page: 0,
        cdisable: 0,
        hostName: process.env.VUE_APP_LOCAL_URL
    };
    return axiosInstance({
        url: process.env.VUE_APP_WS_API_URL + "/PaasService.asmx/GetZoneNewsColumn",
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
    });
}

// 官网获取弄快下列表的接口
export function GetZoneNewsEditListJson(params) {
    let url = process.env.VUE_APP_WS_API_URL + "/ANAService.asmx/GetZoneNewsEditListJson";
    // 在本地运行时需要添加代理配置【因为这个接口会报跨域】
    // if (env === 'development') {
    //     url = '/wsApi/ANAService.asmx/GetZoneNewsEditListJson';
    // }
    return axiosInstance({
        url,
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
    });
}

// 获取新闻详情
export function GetZoneNewContentJson(params) {
    let url = process.env.VUE_APP_WS_API_URL + "/ANAService.asmx/GetZoneNewContentJson";
    // 在本地运行时需要添加代理配置【因为这个接口会报跨域】
    // if (env === 'development') {
    //     url = '/wsApi/ANAService.asmx/GetZoneNewContentJson';
    // }
    return axiosInstance({
        url,
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
    });
}