<template>
    <div class="policyReadBox">
        <div class="tips">
            您搜索的关键词是“<span>{{ keyword }}</span>“，共检索到<span>{{ totalCount }}</span>条有关记录
        </div>
        <div class="menuList">
            <router-link :to="`/dynamicNewsDetail?idx=${item.Idx}`" v-for="(item, index) in models" :key="index"
                class="flex items-center justify-between menuItem">
                <div class="flex-1 flex items-center cursor-pointer overflow-hidden">
                    <div class="icon"></div>
                    <div class="tit flex-1" v-html="highlightKeyword(item.Name)"></div>
                </div>
                <div class="time">{{ item.UpdateTime }}</div>
            </router-link>

            <div class="pagenation" v-if="totalCount > 0">
                <pagenation :totalCount="totalCount" :pageCount="pageCount" @onChangePage="getSearchInfo" />
            </div>
        </div>
    </div>
</template>

<script>
import pagenation from '@/components/pagenation.vue';

import { newsGetZoneNewsSearch } from '@/request/api';
export default {
    components: {
        pagenation
    },
    data() {
        return {
            models: [],
            keyword: '',
            totalCount: 0,

            pageIndex: 1,
            pageCount: 10
        }
    },
    created() {
        this.getSearchInfo();

        // 设置一个全局的课搜索方法
        let _this = this;
        window.mainToSearchMethod = function(){
            _this.getSearchInfo();
        }
    },
    methods: {
        // 获取搜索信心
        getSearchInfo(pdIdx) {
            let key = sessionStorage.getItem('searchKeyWord');
            if (!key) return
            if (typeof pdIdx === 'number') {
                this.pageIndex = pdIdx;
            }

            let sdt = {
                platformNumber: '',
                hostName: process.env.VUE_APP_LOCAL_URL,
                cdisable: 0,
                count: 10,
                index: this.pageIndex,
                keyword: key
            }
            this.keyword = key;
            this.models = [];
            this.totalCount = 0;
            newsGetZoneNewsSearch(sdt).then(res => {
                if (res && res.result) {
                    this.totalCount = res.result[0]?.Total || 0;
                    this.models = res.result.slice(2);
                }
            });
        },
        highlightKeyword(str) {
            return str.split(this.keyword).join("<span style='color:#054BD4;'>" + this.keyword + "</span>")
        }
    },
    destroyed(){
        this.$off('search');
    }
}
</script>

<style lang="less" scoped>
.policyReadBox {
    max-width: 1200px;
    margin: 32px auto 64px;
    min-height: calc(100vh - 332px);

    .tips {
        color: #131414;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;

        >span {
            color: #054BD4;
            font-weight: 700;
        }
    }

    .pagenation {
        padding: 40px 0 24px;
    }

    .menuItem {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px dashed rgba(73, 167, 61, 0.5);
        overflow: hidden;
    }

    .icon {
        width: 14px;
        height: 16px;
        background-image: url('/public/images/menu_icon_1.png');
        background-size: contain;
        margin-right: 8px;
    }

    .tit {
        overflow: hidden;
        color: #131414;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &:hover {
            opacity: .8;
        }
    }

    .time {
        color: #909399;
        font-size: 13px;
        line-height: 22px;
        opacity: 0.5;
    }

    .title {
        color: #1D2129;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .menuList {
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.03), 0px 0px 8px rgba(0, 0, 0, 0.04);
        border-top: 4px solid rgba(73, 167, 61, 0.80);

        padding: 4px 40px 0;
        border-radius: 2px;
    }
}
</style> 