// 接口部分
import axios from "axios";

import Vue from "vue";

const axiosRequest = axios.create({
    // baseURL: process,
    timeout: 60000 // 请求超时时间
});

// 请求拦截器
axiosRequest.interceptors.request.use(
    (config) => {

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosRequest.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    return Promise.reject(error);
});


// 重新封装处理下接口
let requestNumber = 0; // 当前请求的个数
export default function axiosInstance(params, options = {}) {
    return new Promise((resolve) => {
        
        if (!options.isNotShowLoading) {
            if (requestNumber <= 0) {
                Vue.prototype.bus.$emit('showLoading', true);
            }
            requestNumber++;
        }

        axiosRequest(params).then((res) => {
            requestNumber--;
            if (requestNumber <= 0) {
                Vue.prototype.bus.$emit('showLoading', false);
            }
            resolve(res);
        }).catch(error => {
            requestNumber--;
            if (requestNumber <= 0) {
                Vue.prototype.bus.$emit('showLoading', false);
            }
            resolve({});
            console.log('接口错误', error);
        });
    });
};