<template>
    <div class="flex flex-col justify-center ">
        <div class="text-lg font-bold text-[#131414] mb-2 text-center">未来教育与创新发展专业委员会</div>
        <div class="text-sm text-[#909399] text-center mb-6">（以姓氏拼音排序）</div>

        <table>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            <tr>
                <td class="text-center text-label-color px-4 py-3 w-1/12 whitespace-nowrap">序号</td> 
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">姓名</td> 
                <td class="text-center text-label-color px-4 py-3 w-1/2 whitespace-nowrap">单位</td>
                <td class="text-center text-label-color px-4 py-3 whitespace-nowrap">职称职务</td>
            </tr>
            
        </table>
    </div>
</template>

<script>
export default {

}
</script>
<style scoped>
td {
  border: 1px solid #E4E7ED;
  text-align: center;
}
</style>