export const organizationTabData = [
    {
        name: "专委会介绍",
        url: "introduce",
    },
    // {
    //     name: "专家团队",
    //     url: "team",
    // },
    // {
    //     name: '专委会成员',
    //     url: 'member'
    // },
    {
        name: '专委会章程',
        url: 'constitution'
    }
];
