<template>
    <div class="flex flex-col sticky top-4 w-[196px] h-[112px] rounded-sm border-solid border-[#EBEEF5] border-x border-t ">
        <div class="flex items-center flex-1 box-border px-4 border-solid border-[#EBEEF5] border-b text-[#606266] text-base cursor-pointer" 
            :class="{'bg-[#ECF7EB] !text-[#228C14] font-bold': activeIndex == index}"
            v-for="(item, index) in organizationTabData" :key="index"
            @click="onClickOrganizationTab(item)"
            >
            <span>{{ item.name }}</span>
        </div>
    </div>
</template>

<script>
import { organizationTabData } from './mode/organizationTabData';

export default {
    name: 'organizationTab',
    data() {
        return {
            organizationTabData,
        }
    },
    methods: {
        onClickOrganizationTab(tabItem) {
            this.$router.replace('/organization/' + tabItem.url).catch(() => {});
        }
    },
    computed: {
        activeIndex() {
            return organizationTabData.findIndex(item => item.url == this.$route.path.split('/')[2]);
        }
    }
}
</script>
