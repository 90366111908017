<template>
    <div class="flex flex-col">
        <div class="flex">
            <div class="w-[162px] h-[232px] mr-5">
                <img src="" alt="" class="w-full h-full">
            </div>
            <div class="flex-1">
                <div class="flex justify-between items-center">
                    <div class="text-[22px] text-[#1D2129]">
                        <span class="font-bold mr-3">姓名</span>
                        <span>XING MING</span>
                    </div>

                    <div class="text-[20px] text-[#1D2129] font-bold">职位称号</div>
                </div>

                <div class="mt-3 text-label-color text-base leading-7 pb-6">
                    1976年毕业于浙江大学；1992年获奥地利格拉茨大学博士学位。
                    曾任中科院安徽光机所研究员，德国马普量子光学所、奥地利格拉茨大学、日本大阪大学访问学者，
                    曾任中国驻德国大使馆二秘/一秘，中科大研究生院副院长，中科院研究生院副院长，广西壮族自治区科技厅副厅长，中科院理化所副所长、副书记，
                    中国感光学会秘书长。现任中科院理化所研究员（二级）、欧美同学会德奥分会副会长、中科院老科协副理事长。多年从事科研管理工作，
                    专业研究领域为激光及其应用研究、光子学及微钠加工，在国际上首次报道氦587.56nm 激光谱线，首次报道TEA Cl2激光（258nm）的运转；
                    担任课题组长或主要骨干完成国家“七五科技攻关”和“八五科技攻关”准分子激光相关课题，“便携式大能量光纤激光加工机”、“两用望远镜系统”等；
                    中科院知识创新工程重要方向性课题、科技部国际合作项目“等离子人工介质与光功能器件应用研究”、“中日先进光子学联合实验室”等；
                    国家973纳米专项“无掩模纳米光刻机理、关键技术、工艺创新与应用基础研究”；国家基金委“可见光三维Luneburg透镜的广视场理想成像及其多光子制备”等课题；
                    财政部重大专项“深紫外固态激光前沿装备研制”等项目。
                    在国内外刊物发表论文二百余篇；获15项中国、美、日专利。
                </div>
            </div>
        </div>

        <div class="w-full border-t-2 border-t-[#49A73D] border-dotted mb-6 opacity-50"></div>

        <div class="flex">
            <div class="w-[162px] h-[232px] mr-5">
                <img src="" alt="" class="w-full h-full">
            </div>
            <div class="flex-1">
                <div class="flex justify-between items-center">
                    <div class="text-[22px] text-[#1D2129]">
                        <span class="font-bold mr-3">姓名</span>
                        <span>XING MING</span>
                    </div>

                    <div class="text-[20px] text-[#1D2129] font-bold">职位称号</div>
                </div>

                <div class="mt-3 text-label-color text-base leading-7 pb-6">
                    1976年毕业于浙江大学；1992年获奥地利格拉茨大学博士学位。
                    曾任中科院安徽光机所研究员，德国马普量子光学所、奥地利格拉茨大学、日本大阪大学访问学者，
                    曾任中国驻德国大使馆二秘/一秘，中科大研究生院副院长，中科院研究生院副院长，广西壮族自治区科技厅副厅长，中科院理化所副所长、副书记，
                    中国感光学会秘书长。现任中科院理化所研究员（二级）、欧美同学会德奥分会副会长、中科院老科协副理事长。多年从事科研管理工作，
                    专业研究领域为激光及其应用研究、光子学及微钠加工，在国际上首次报道氦587.56nm 激光谱线，首次报道TEA Cl2激光（258nm）的运转；
                    担任课题组长或主要骨干完成国家“七五科技攻关”和“八五科技攻关”准分子激光相关课题，“便携式大能量光纤激光加工机”、“两用望远镜系统”等；
                    中科院知识创新工程重要方向性课题、科技部国际合作项目“等离子人工介质与光功能器件应用研究”、“中日先进光子学联合实验室”等；
                    国家973纳米专项“无掩模纳米光刻机理、关键技术、工艺创新与应用基础研究”；国家基金委“可见光三维Luneburg透镜的广视场理想成像及其多光子制备”等课题；
                    财政部重大专项“深紫外固态激光前沿装备研制”等项目。
                    在国内外刊物发表论文二百余篇；获15项中国、美、日专利。
                </div>
            </div>
        </div>

        <div class="w-full border-t-2 border-t-[#49A73D] border-dotted mb-6 opacity-50"></div>

        <div class="flex">
            <div class="w-[162px] h-[232px] mr-5">
                <img src="" alt="" class="w-full h-full">
            </div>
            <div class="flex-1">
                <div class="flex justify-between items-center">
                    <div class="text-[22px] text-[#1D2129]">
                        <span class="font-bold mr-3">姓名</span>
                        <span>XING MING</span>
                    </div>

                    <div class="text-[20px] text-[#1D2129] font-bold">职位称号</div>
                </div>

                <div class="mt-3 text-label-color text-base leading-7 pb-6">
                    1976年毕业于浙江大学；1992年获奥地利格拉茨大学博士学位。
                    曾任中科院安徽光机所研究员，德国马普量子光学所、奥地利格拉茨大学、日本大阪大学访问学者，
                    曾任中国驻德国大使馆二秘/一秘，中科大研究生院副院长，中科院研究生院副院长，广西壮族自治区科技厅副厅长，中科院理化所副所长、副书记，
                    中国感光学会秘书长。现任中科院理化所研究员（二级）、欧美同学会德奥分会副会长、中科院老科协副理事长。多年从事科研管理工作，
                    专业研究领域为激光及其应用研究、光子学及微钠加工，在国际上首次报道氦587.56nm 激光谱线，首次报道TEA Cl2激光（258nm）的运转；
                    担任课题组长或主要骨干完成国家“七五科技攻关”和“八五科技攻关”准分子激光相关课题，“便携式大能量光纤激光加工机”、“两用望远镜系统”等；
                    中科院知识创新工程重要方向性课题、科技部国际合作项目“等离子人工介质与光功能器件应用研究”、“中日先进光子学联合实验室”等；
                    国家973纳米专项“无掩模纳米光刻机理、关键技术、工艺创新与应用基础研究”；国家基金委“可见光三维Luneburg透镜的广视场理想成像及其多光子制备”等课题；
                    财政部重大专项“深紫外固态激光前沿装备研制”等项目。
                    在国内外刊物发表论文二百余篇；获15项中国、美、日专利。
                </div>
            </div>
        </div>

        <div class="w-full border-t-2 border-t-[#49A73D] border-dotted mb-6 opacity-50"></div>

        <div class="flex">
            <div class="w-[162px] h-[232px] mr-5">
                <img src="" alt="" class="w-full h-full">
            </div>
            <div class="flex-1">
                <div class="flex justify-between items-center">
                    <div class="text-[22px] text-[#1D2129]">
                        <span class="font-bold mr-3">姓名</span>
                        <span>XING MING</span>
                    </div>

                    <div class="text-[20px] text-[#1D2129] font-bold">职位称号</div>
                </div>

                <div class="mt-3 text-label-color text-base leading-7 pb-6">
                    1976年毕业于浙江大学；1992年获奥地利格拉茨大学博士学位。
                    曾任中科院安徽光机所研究员，德国马普量子光学所、奥地利格拉茨大学、日本大阪大学访问学者，
                    曾任中国驻德国大使馆二秘/一秘，中科大研究生院副院长，中科院研究生院副院长，广西壮族自治区科技厅副厅长，中科院理化所副所长、副书记，
                    中国感光学会秘书长。现任中科院理化所研究员（二级）、欧美同学会德奥分会副会长、中科院老科协副理事长。多年从事科研管理工作，
                    专业研究领域为激光及其应用研究、光子学及微钠加工，在国际上首次报道氦587.56nm 激光谱线，首次报道TEA Cl2激光（258nm）的运转；
                    担任课题组长或主要骨干完成国家“七五科技攻关”和“八五科技攻关”准分子激光相关课题，“便携式大能量光纤激光加工机”、“两用望远镜系统”等；
                    中科院知识创新工程重要方向性课题、科技部国际合作项目“等离子人工介质与光功能器件应用研究”、“中日先进光子学联合实验室”等；
                    国家973纳米专项“无掩模纳米光刻机理、关键技术、工艺创新与应用基础研究”；国家基金委“可见光三维Luneburg透镜的广视场理想成像及其多光子制备”等课题；
                    财政部重大专项“深紫外固态激光前沿装备研制”等项目。
                    在国内外刊物发表论文二百余篇；获15项中国、美、日专利。
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {

}
</script>