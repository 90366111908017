<template>
    <div class="flex flex-col justify-center ">
        <div class="text-lg font-bold text-[#131414] mb-5 text-center">中国高技术产业发展促进会未来教育与创新发展专业委员会</div>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">一 成立宗旨</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <div class="text-label-color leading-8 indent-8">
            未来教育是支撑高技术产业发展、落实创新驱动发展战略的重要手段，受到国内外高度重视。
            未来教育质量的高低对落实创新驱动发展战略至关重要。
            高技术产业如何为未来教育提供创新支撑，未来教育如何为高技术产业发展培养创新人才，
            最终实现高技术产业与未来教育融合发展，成为未来一个时期我国落实创新驱动发展战略的重要议题。
            未来教育与创新发展专委会（以下简称专委会）旨在从事未来教育战略与政策研究，
            组织开展课程与教学领域的学术活动，协同高校、科研院所开展相关设备、系统、内容开发，
            组织论坛、专业展览，推动数字技术、数字内容与教育加速融合。
        </div>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">二 业务范围</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <div class="text-label-color leading-8 indent-8">
            1.开展未来教育战略与政策研究，编辑出版相关期刊、报告，支撑主管部门制定决策，
            组织编制未来教育标准，加速培训未来教育人才；
        </div>
        <div class="text-label-color leading-8 indent-8">
            2.组织、开展课程与教学领域的学术活动，
            交流课程教学改革领域、教育教学产品应用领域（尤其是信息化生态下）的科研成果和实践经验；
        </div>
        <div class="text-label-color leading-8 indent-8">
            3.在教育研究界、实践界、教育产业界之间搭建沟通交流的平台；
        </div>
        <div class="text-label-color leading-8 indent-8">
            4.组织论坛和专业展览推动国内外产学研交流与合作;
        </div>
        <div class="text-label-color leading-8 indent-8">
            5.协同高校、科研院所及企业开展相关设备、系统、内容开发，
            推动科技成果转化、研发教育产品标准、深化产品应用，切实推动数字技术、数字内容与教育加速融合。
        </div>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">三 组织架构</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <div class="text-label-color leading-8 indent-8">
            专委会接受中国高技术产业发展促进会（以下简称高促会）与首师大联合指导，
            由专委会学术委员会负责专业引领，秘书处设立在首都师范大学以及创而新大厦。
        </div>
        <div class="text-label-color leading-8 indent-8">
            秘书处日常运营实行秘书长负责制，接受专委会监督和管理，
            重要决议需报高促会审批。开办经费由秘书处负责筹办，经费来源为会员捐赠、市场经营等。
        </div>

        <div class="flex items-center my-2">
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
            <div class=" leading-8 text-[#131414] mx-2">四 现任领导</div>
            <div class="flex-1 border-t border-[#DCDFE6]"></div>
        </div>

        <div class="text-label-color leading-8 indent-8">
            李有增同志任第一届中国高技术产业发展促进会未来教育与创新发展专业委员会主任委员；
            穆荣平同志任第一届中国高技术产业发展促进会未来教育与创新发展专业委员会学术委员会主任委员；
            蔡可、窦桂梅、郭柏、李慧勤、李佳健、王孝炯、许红伟、赵勇同志任第一届中国高技术产业发展促进会未来教育与创新发展专业委员会副主任委员；
            窦彦莉同志任第一届中国高技术产业发展促进会未来教育与创新发展专业委员会秘书长。
        </div>
    </div>
</template>

<script>
export default {

}
</script>