<template>
    <div class="mainfooter flex items-center justify-center">
        <div>
            <div class="w1 flex items-center justify-between">
                <span>通讯地址：北京市海淀区万泉庄路15号（100089）创而新大厦4层</span>
                <span>邮箱地址：keli@k12smartedu.com</span>
                <!-- <span>电话：（010）8255 1800</span> -->
            </div>
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023028038号-1</a>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.mainfooter {
    width: 100%;
    height: 130px;
    background: #555;

    color: #FFF;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    .w1{
        width: 670px;
        margin-bottom: 8px;
    }
}
</style>