<template>
    <div class="flex items-center justify-center w-full">
        <div class="text-[#606266] text-sm mr-4">共 {{ totalCount }} 条</div>

        <div class="flex items-center">
            <div class="pagenation-button bg-[#f5f7fa]" @click="onClickChangeCurrentPage('previous')">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M8.32617 2.03698L3.80067 6.69898C3.71871 6.78094 3.67773 6.87894 3.67773 6.99298C3.67773 7.10702 3.71871 7.20954 3.80067 7.30054L8.32617 11.9625C8.40813 12.0355 8.5038 12.0719 8.61317 12.0719C8.72255 12.0719 8.81821 12.0309 8.90017 11.949C8.98213 11.867 9.02311 11.7714 9.02311 11.662C9.02311 11.5526 8.98213 11.4569 8.90017 11.375L4.63455 6.99998L8.90017 2.62498C8.98213 2.54302 9.02311 2.44735 9.02311 2.33798C9.02311 2.2286 8.98213 2.13294 8.90017 2.05098C8.81821 1.96902 8.72255 1.92804 8.61317 1.92804C8.5038 1.92804 8.40813 1.9645 8.32617 2.03741V2.03698Z"
                        :fill="currentPage == 1 ? '#08080A4f' : '#606266'" />
                </svg>
            </div>

            <template v-if="totalPage <= 8">
                <div class="pagenation-button bg-[#F5F7FA]" :class="{ '!bg-[#49A73D] !text-[#fff]': currentPage == item }" v-for="item in totalPage" @click="onClickNormalIndex(item)">{{ item }}</div>
            </template>

            <template v-else>
                <div class="pagenation-button bg-[#F5F7FA]" :class="{ '!bg-[#49A73D] !text-[#fff]': currentPage == item }" v-for="item in pagenationList" @click="onClickOverflowIndex(item)">{{ item }}
                </div>
            </template>

            <div class="pagenation-button bg-[#F5F7FA]" @click="onClickChangeCurrentPage('next')">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M4.67299 2.037C4.59103 2.12829 4.55005 2.22862 4.55005 2.338C4.55005 2.44737 4.59103 2.54304 4.67299 2.625L8.93861 7L4.67299 11.375C4.59103 11.457 4.55005 11.5526 4.55005 11.662C4.55005 11.7714 4.59103 11.867 4.67299 11.949C4.75494 12.031 4.85061 12.0719 4.95999 12.0719C5.06936 12.0719 5.16503 12.0355 5.24699 11.9626L9.77249 7.30056C9.85444 7.2186 9.89542 7.11827 9.89542 6.99956C9.89542 6.88085 9.85444 6.78052 9.77249 6.69856L5.24699 2.03656C5.16503 1.96364 5.06936 1.92719 4.95999 1.92719C4.85061 1.92719 4.75494 1.96364 4.67299 2.03656V2.037Z"
                        :fill="currentPage == this.totalPage ? '#08080A4f' : '#606266'" />
                </svg>
            </div>
        </div>

        <div class="flex items-center h-full">
            前往
            <input type="text" class="w-14 h-8 border border-[#DCDFE6] outline-none text-center mx-2 rounded-sm" v-model="jumpPage" @keydown.enter="onSetJumpPage" @blur="onSetJumpPage">
            页
        </div>
    </div>
</template>

<script>
export default {
    props: ['totalCount', 'pageCount', 'initPageIndex'],
    data() {
        return {
            currentPage: 1, // 当前页码
            pagenationList: [], // 特殊分页数组（指总页数大于8页的）
            lastOverflowIndex: 0,
            firstOverflowIndex: 0,
            jumpPage: 1,
            totalPage: 1
        }
    },
    methods: {
        // 点击上一页按钮
        onClickChangeCurrentPage(type) {
            if ((this.currentPage == 1 && type == 'previous') || (this.currentPage == this.totalPage && type == 'next')) { return };

            this[this.totalPage > 8 ? 'onClickOverflowIndex' : 'onClickNormalIndex'](Number(this.currentPage) + (type == 'next' ? 1 : -1))
        },
        // 点击特殊分页按钮
        onClickOverflowIndex(item) {
            if (!Number(item)) return;

            this.currentPage = item;

            const formatPageList = JSON.parse(JSON.stringify(this.pagenationList));

            if (item >= this.lastOverflowIndex) {
                if ((Number(item) + 7) >= this.totalPage) {
                    for (var i = 7; i > -1; i--) {
                        formatPageList[i] = this.totalPage - (7 - i);
                    }
                } else {
                    for (var i = 0; i < 6; i++) {
                        formatPageList[i] = Number(item) + i;
                    }
                }
            }

            if (item < this.firstOverflowIndex) {
                if (Number(item - 5) >= 6) {
                    for (var i = 0; i < 6; i++) {
                        formatPageList[i] = item - (5 - i);

                        formatPageList[6] = '···'
                    }
                } else {
                    for (var i = 0; i < 6; i++) {
                        formatPageList[i] = i + 1;

                        formatPageList[6] = '···'
                    }
                }
            }

            this.pagenationList = formatPageList;

            this.$emit('onChangePage', this.currentPage);
        },
        // 点击普通分页按钮
        onClickNormalIndex(item) {
            this.currentPage = item;

            this.$emit('onChangePage', this.currentPage);
        },
        // 点击设置跳转页
        onSetJumpPage() {
            if (this.jumpPage <= 0 || this.jumpPage > this.totalPage) {
                this.jumpPage = this.currentPage;
            } else {
                this[this.totalPage > 8 ? 'onClickOverflowIndex' : 'onClickNormalIndex'](this.jumpPage)
            }
        }
    },
    watch: {
        pagenationList: {
            handler: function (newValue, oldValue) {
                if (newValue) {
                    this.lastOverflowIndex = newValue[5]; // 记录最后需要赋值分页的下标

                    this.firstOverflowIndex = newValue[0]; // 记录第一个需要赋值分页的下标
                }
            },
            deep: true
        }
    },
    mounted() {
        this.pagenationList = [1, 2, 3, 4, 5, 6, '···', this.totalPage]; // 特殊分页首次赋值

        this.totalPage = Math.ceil(this.totalCount / this.pageCount);

        if (this.initPageIndex) {
            this[this.totalPage > 8 ? 'onClickOverflowIndex' : 'onClickNormalIndex'](this.initPageIndex)
        }
    }
}
</script>

<style scoped>
.pagenation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    color: #606266;
    cursor: pointer;
}
</style>