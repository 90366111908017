<template>
    <div class="bannerList flex justify-between">
        <div class="bannerBox">
            <div class="swiper" id="mySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in bannerList" :key="item.Idx">
                        <router-link :to="`/dynamicNewsDetail?idx=${item.Idx}`" class="w-full h-full block">
                            <img :src="item.Url" class="w-full h-full object-cover" alt="" srcset="" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="bannerTips flex items-center justify-between" v-if="bannerList[activeIndex]">
                <div class="flex-1 truncate txt">{{ bannerList[activeIndex].Name }}</div>
                <div class="bannerPoint flex items-center">
                    <div class="point cursor-pointer" v-for="index in bannerList.length" :key="'point_' + index"
                        :class="{ active: index - 1 == activeIndex }" @click="changeActiveIndex(index - 1)"></div>
                </div>
            </div>
        </div>
        <div class="infoList flex-1">
            <div class="infoItem" v-for="(item, index) in bannerList" :key="'ban_' + index"
                :class="{ active: index == activeIndex }" @mouseenter="changeActiveIndex(index)">
                <div class="center">
                    <router-link :to="`/dynamicNewsDetail?idx=${item.Idx}`"
                        class="tit truncate transition-colors cursor-pointer block">{{ item.Name }}</router-link>
                    <div class="text transition-colors">{{ item.ContentReal }}</div>
                </div>
            </div>
            <div class="activeLine" :style="{ top: lineTop + 'px' }" v-show="bannerList.length > 0"></div>
        </div>
    </div>
</template>

<script>
// https://www.swiper.com.cn/api/start/new.html
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            bannerList: [], // 展示的轮播列表
            activeIndex: 0,

            infoItemRect: {},

            // 组件相关模块
            swiperOption: {
                // loop: true,
                // autoplay: {
                //     autoplay: true,
                //     delay: 3000,
                //     stopOnLastSlide: false,
                //     disableOnInteraction: true,
                // },

            }
        }
    },
    computed: {
        lineTop() {
            let { height } = this.infoItemRect;
            return (height || 0) / 2 + this.activeIndex * height
        }
    },
    watch: {
        list(arr) {
            if (arr.length > 0) {
                // 只显示含有图片的新闻
                this.bannerList = arr.filter(r => !!r.Url).slice(0, 3);
                this.$nextTick(() => {
                    this.swiperInit();
                });
            }
        }
    },
    methods: {
        swiperInit() {
            let _this = this;
            this.mySwiper = new Swiper('#mySwiper', {
                loop: this.list.length > 1,
                autoplay: this.list.length > 1,//可选选项，自动滑动
                on: {
                    init() {
                        _this.$nextTick(() => {
                            _this.getItemRect();
                        });
                    },
                    slideChange(e) {
                        // console.log(e, e.realIndex);
                        _this.activeIndex = e.realIndex;
                    }
                }
            });
        },
        changeActiveIndex(index) {
            this.activeIndex = index;
            // 跳转完毕后再次恢复自动播放
            this.mySwiper.slideToLoop(index);
            this.mySwiper.autoplay.start();
        },
        // 计算当前每一块的高度
        getItemRect() {
            let infoItemDoms = document.getElementsByClassName('infoItem');

            if (infoItemDoms.length > 0) {
                this.infoItemRect = infoItemDoms[0].getBoundingClientRect();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bannerList {
    padding: 16px 0 48px;

    .bannerBox {
        width: 590px;
        height: 332px;
        flex-shrink: 0;
        position: relative;

        .bannerPoint {
            .point {
                width: 6px;
                height: 6px;
                border-radius: 30px;
                border: 1px solid #FFF;
                margin: 0 3px;
                box-sizing: border-box;

                &.active {
                    background: #FFF;
                }
            }
        }

        .bannerTips {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background: rgba(0, 0, 0, 0.60);
            z-index: 10;
            padding: 0 17px 0 12px;

            .txt {
                color: #FFF;
                font-size: 15px;
                line-height: 24px;
                padding-right: 20px;
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .swiper-slide {
            background: #f2f2f2;
        }
    }

    .infoList {
        height: 332px;
        margin-left: 18px;
        padding-left: 16px;
        overflow-y: auto;
        position: relative;

        .activeLine {
            position: absolute;
            top: 55px;
            left: 0;
            width: 4px;
            height: 76px;
            background: rgba(105, 174, 64, 0.6);
            margin-top: -38px;

            transition: top linear .25s;

            &::before {
                content: '';
                display: block;
                width: 4px;
                height: 18px;
                background: #69AE40;
            }
        }

        .infoItem {
            height: 33%;
            position: relative;
            border-bottom: 0.5px dashed #69AE40;

            &:nth-last-of-type(2) {
                border-bottom: none;
            }

            .center {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }

            .tit {
                color: #131414;
                font-size: 16px;
                line-height: 24px;

                &:hover {
                    opacity: .8;
                }
            }

            .text {
                color: #909399;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin-top: 12px;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &.active {
                .tit {
                    color: #131414;
                    font-weight: 700;
                }

                .text {
                    color: #131414;
                }
            }
        }
    }
}
</style>