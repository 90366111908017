<template>
    <div class="dynamicNewsDetail">
        <div class="titleBox">
            <p class="tit">{{ info.Name || '--' }}</p>
            <div class="info">
                <span>发布时间: {{ info.PulbishTime || '--' }}</span>
                <span>发布人: {{ info.Publisher || '--' }}</span>
            </div>

            <div class="backBtn cursor-pointer" @click="$router.go(-1)">【返回】</div>
        </div>

        <div class="detailContent" v-html="info.Content"></div>
    </div>
</template>

<script>
import { GetZoneNewContentJson } from '@/request/api';
export default {
    data() {
        return {
            info: {}
        }
    },
    methods: {
        getNewContent() {
            var param = {
                "session": "",
                "page": 1,
                "idx": this.$route.query.idx || '',
            };

            GetZoneNewContentJson(param).then(res => {
                if (res.result && res.result.length > 0) {
                    this.info = res.result[0];
                }
            });
        }
    },
    created() {
        this.getNewContent();
    }
}
</script>

<style lang="less">
.dynamicNewsDetail {
    max-width: 1200px;
    min-height: calc(100vh - 354px);
    margin: 32px auto 64px;
    border-top: 4px solid rgba(73, 167, 61, 0.80);

    background: #FFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.03), 0px 0px 8px rgba(0, 0, 0, 0.04);
    border-radius: 2px;

    .detailContent {
        padding: 24px 32px 24px 40px;
        color: #606266;

        img, svg{
            display: inline-block;
        }
    }

    .titleBox {
        text-align: center;
        border-bottom: 1px dashed rgba(73, 167, 61, 0.5);
        padding-bottom: 24px;
        position: relative;
        margin: 0 40px;

        .backBtn {
            color: #49A73D;
            text-align: right;
            font-size: 14px;
            line-height: 22px;
            position: absolute;
            top: 12px;
            right: -24px;

            &:hover {
                opacity: .8;
            }
        }

        .tit {
            color: #131414;
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            padding: 24px 0 12px;
        }

        .info {
            color: #909399;
            font-size: 14px;
            line-height: 22px;
            position: relative;

            >span:nth-of-type(1) {
                padding-right: 16px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 14px;
                    background: #C0C4CC;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -7px;
                }
            }

            >span:nth-of-type(2) {
                padding-left: 16px;
            }
        }
    }
}
</style>