import { render, staticRenderFns } from "./stayTuned.vue?vue&type=template&id=66fae714&scoped=true"
import script from "./stayTuned.vue?vue&type=script&lang=js"
export * from "./stayTuned.vue?vue&type=script&lang=js"
import style0 from "./stayTuned.vue?vue&type=style&index=0&id=66fae714&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fae714",
  null
  
)

export default component.exports