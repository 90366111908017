<template>
    <div class="policyReadBox">
        <div class="title text-2xl font-bold text-[#1D2129] mb-4">政策解读</div>

        <div class="menuList">
            <div v-for="item in policyDataList" :key="item.Idx" class="flex items-center justify-between menuItem"
                @click="jumpToDetail(item)">
                <div class="flex items-center cursor-pointer flex-1 flex-shrink-0 w80">
                    <div class="icon"></div>
                    <div class="tit flex-1  flex-shrink-0">{{ item.Name }}</div>
                </div>  
                <div class="time">{{ item.UpdateTime }}</div>
            </div>

            <div class="text-center noneInfo" v-if="policyDataList.length <= 0">
                暂无数据
            </div>

            <div class="pagenation" v-if="pageCount > 0">
                <pagenation :totalCount="pageCount" :pageCount="page.count" @onChangePage="onChangePage" />
            </div>
        </div>
    </div>
</template>

<script>
import pagenation from '@/components/pagenation.vue';
import { GetZoneNewsColumn, GetZoneNewsEditListJson } from '@/request/api';
export default {
    components: {
        pagenation
    },
    data() {
        return {
            policyDataList: [],
            policyIdx: '',
            page: {
                "page": 1,
                count: 10,
            },
            pageCount: 0,
        }
    },
    created() {
        this.toGetZoneNewsColumn();
    },
    methods: {
        // 跳转详情
        jumpToDetail(item) {
            this.$router.push(`/policyReadDetail?idx=${item.Idx}`);
        },
        // 获取政策
        toGetZoneNewsColumn() {
            GetZoneNewsColumn().then(res => {
                let result = res ? res.result : [];
                if (result && result.length > 0) {
                    let zcItm = result.find(r => /政策/.test(r.Name));
                    this.policyIdx = zcItm.Idx;
                    // 获取政策
                    this.getNewsByIdx();
                }
            });
        },
        // 分页变化
        onChangePage(pageIndex) {
            this.page.page = pageIndex;
            this.getNewsByIdx();
        },

        getNewsByIdx() {
            let sdt = {
                "session": "",
                "page": 1,
                count: this.page.count,
                "index": this.page.page,
                "cidx": this.policyIdx,
                "ndisable": 0,
                "lencontent": 100
            };
            this.policyDataList = [];
            GetZoneNewsEditListJson(sdt).then(res => {
                if (res && res.result) {
                    let child = res.result[2].ChildList;
                    if (child) {
                        this.pageCount = res.result[0].Total || 0;
                        this.policyDataList = child;
                    }
                }
            });
        },
    },
}
</script>

<style lang="less" scoped>
.policyReadBox {
    max-width: 1200px;
    min-height: calc(100vh - 354px);
    margin: 32px auto 64px;

    .w80{
        width: 80%;
    }

    .noneInfo {
        line-height: 480px;
    }

    .pagenation {
        padding: 40px 0 24px;
    }

    .menuItem {
        padding: 20px 0;
        border-bottom: 1px dashed rgba(73, 167, 61, 0.5);
    }

    .icon {
        width: 14px;
        height: 16px;
        background-image: url('/public/images/menu_icon_1.png');
        background-size: contain;
        margin-right: 8px;
    }

    .tit {
        overflow: hidden;
        color: #131414;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        

        &:hover {
            opacity: .8;
        }
    }

    .time {
        color: #909399;
        font-size: 13px;
        line-height: 22px;
        opacity: 0.5;
    }

    .title {
    }

    .menuList {
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.03), 0px 0px 8px rgba(0, 0, 0, 0.04);
        border-top: 4px solid rgba(73, 167, 61, 0.80);

        padding: 4px 40px 0;
        border-radius: 2px;
        position: relative;
    }
}
</style> 