<template>
    <div class="mainMenuListBox">
        <div v-for="item in list" :key="item.Idx" class="flex items-center justify-between menuItem"
            @click="jumpToDetail(item)">
            <div :class="iconType === 'icon' ? 'icon' : 'icon_cir'"></div>
            <div class="flex-1 tit cursor-pointer transition-colors flex-shrink-0">{{ item.Name }}</div>
            <div class="time">{{ format(item.UpdateTime, 'yyyy-MM-dd') }}</div>
        </div>

        <div v-if="list.length <= 0" class="emptyData">
            暂无数据
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        // 图标类型- icon: 图标  cir: 圆形
        iconType: {
            type: String,
            default: 'icon'
        },
        // 详情页
        detailPath: {
            type: String,
            default: ''
        }
    },
    methods: {
        jumpToDetail(item) {
            if (this.detailPath) {
                this.$router.push(`${this.detailPath}?idx=${item.Idx}`);
            }
        },

        // 时间格式转换
        format(time, format) {
            if(!time) return ''
            let t = new Date(time);
            let tf = function (i) { return (i < 10 ? '0' : '') + i };
            return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
                switch (a) {
                    case 'yyyy':
                        return tf(t.getFullYear());
                        break;
                    case 'MM':
                        return tf(t.getMonth() + 1);
                        break;
                    case 'mm':
                        return tf(t.getMinutes());
                        break;
                    case 'dd':
                        return tf(t.getDate());
                        break;
                    case 'HH':
                        return tf(t.getHours());
                        break;
                    case 'ss':
                        return tf(t.getSeconds());
                        break;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.mainMenuListBox {
    .emptyData {
        text-align: center;
        padding: 40px 0;
        color: #86909C;
        font-size: 14px;
    }

    .menuItem {
        margin-top: 16px;
    }

    .icon {
        width: 14px;
        height: 16px;
        background-image: url('/public/images/menu_icon_1.png');
        background-size: contain;
        margin-right: 8px;
    }

    .icon_cir {
        width: 6px;
        height: 6px;
        background: #44A138;
        border-radius: 50%;
        margin-right: 8px;
    }

    .tit {
        width: 0;
        overflow: hidden;
        color: rgb(19, 20, 20);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-right: 16px;

        &:hover {
            color: #44A138;
            opacity: .8;
        }

        &:active {
            color: #44A138;
        }
    }

    .time {
        color: #C0C4CC;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
}
</style>