<template>
    <div class="organization-wrapper flex justify-center pt-8 pb-16">
        <div class="px-[120px] max-w-[1440px] w-full h-full max-xl:px-20 max-lg:px-10 max-md:px-4">
            <div class="text-2xl font-bold pb-[16px]">组织介绍</div>

            <div class="flex justify-between">
                <organizationTab />

                <div class="flex-1 h-full px-10 pt-6 pb-10 border-t-4 border-[#49A73D] ml-[24px] shadow-md">
                    <router-view />
                </div>
            </div>

        </div>
    </div>
</template>
 
<script>
import organizationTab from './organizationTab.vue';

export default {
    components: {
        organizationTab
    }
}
</script>

<style scoped>
.organization-wrapper {
    min-height: calc(100vh - 258px);
}
</style>
