<template>
    <div class="mainTitle">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <p class="tit font-bold text-2xl">{{ title }}</p>
                <slot name="left"></slot>
            </div>
            <div class="more text-sm flex items-center cursor-pointer" @click="jumpMorePage" v-if="!noMore">
                <span>查看更多</span>
                <img src="/images/icon_right.png" class="icon" />
            </div>
        </div>

        <div class="line"></div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        // 查看更多跳转的页面
        morePath: {
            type: String,
            default: ''
        },
        // 是否含有查看更多
        noMore: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        jumpMorePage() {
            if (this.morePath) {
                if (/^http/.test(this.morePath)) {
                    // window.location.href = this.morePath;
                    window.open(this.morePath);
                } else {
                    this.$router.push(this.morePath);
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.mainTitle {

    height: 46px;
    border-bottom: 0.5px solid #DCDFE6;
    position: relative;

    .line {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 96px;
        height: 4px;
        background: #44A138;
    }

    .tit {
        color: #1D2129;
    }

    .more {
        color: #86909C;

        .icon {
            width: 12px;
            height: 22px;
            margin-left: 2px;
        }

        &:hover {
            opacity: .8;
        }
    }
}
</style>