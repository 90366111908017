import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// tailwind
import './tailwind.css';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';
Vue.use(VueAwesomeSwiper);

// 应用导航，分页，自动播放等功能
import Swiper2, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper'
Swiper2.use([Navigation, Pagination, EffectFade, Autoplay]);

Vue.config.productionTip = false;

Vue.prototype.bus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
